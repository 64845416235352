import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"

import { Layout } from "../components/layouts/main-layout"
import { groupBy } from "ramda"
import { SponsorType } from "components/admin/edit-sponsors"

interface Logo {
  name: string
  link: string
  type: SponsorType
  logo: {
    publicURL: string
  }
}

const Q_Sponsors = graphql`
  query {
    allStrapiSponsors(filter: { is_visible: { eq: true } }) {
      nodes {
        name
        type
        link
        logo {
          publicURL
        }
      }
    }
  }
`

const useStyles = makeStyles((theme: Theme) => ({
  sectionContainer: {
    padding: "1rem",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyItems: "center",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: "auto",
    gap: "26px",
    gridAutoRows: "1fr 1fr",
    gridAutoColumns: "1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "auto",
    },
  },
  sponsorContainer: {
    background: "rgba(255,241,113,0.77)",
    borderRadius: "10px",
    padding: "1rem 3rem",
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem",
    },
    height: "100%",
  },
}))

const SponsorsPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const translations = {
    [SponsorType.Sponsor]: t("admin.sponsor-type-sponsor"),
    [SponsorType.Patron]: t("admin.sponsor-type-patron"),
    [SponsorType.Information]: t("admin.sponsor-type-information"),
    [SponsorType.Friend]: t("admin.sponsor-type-friend"),
  }

  const translationsPlural = {
    [SponsorType.Sponsor]: t("admin.sponsor-type-sponsor-plural"),
    [SponsorType.Patron]: t("admin.sponsor-type-patron-plural"),
    [SponsorType.Information]: t("admin.sponsor-type-information-plural"),
    [SponsorType.Friend]: t("admin.sponsor-type-friend-plural"),
  }

  const {
    allStrapiSponsors: { nodes },
  } = useStaticQuery(Q_Sponsors)

  const groupedSponsors = groupBy(({ type }) => type, nodes)

  const sortedSponsors = {
    [SponsorType.Patron]: groupedSponsors[SponsorType.Patron],
    [SponsorType.Sponsor]: groupedSponsors[SponsorType.Sponsor],
    [SponsorType.Information]: groupedSponsors[SponsorType.Information],
    [SponsorType.Friend]: groupedSponsors[SponsorType.Friend],
  }

  return (
    <Layout pageName={t("page.sponsors")}>
      <div className={classes.sectionContainer}>
        {Object.entries(sortedSponsors).map(([type, sponsors = []]) => (
          <Grid
            key={type}
            className={classes.sponsorContainer}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              {
                (sponsors.length > 1 ? translationsPlural : translations)[
                  type as SponsorType
                ]
              }
            </Typography>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              wrap="wrap"
              alignContent="center"
              style={{
                margin: "auto",
                position: "relative",
              }}
            >
              {sponsors.map(({ name, link, logo: { publicURL } }) => {
                return (
                  <a
                    key={name}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      margin: "auto",
                    }}
                  >
                    <img
                      src={publicURL}
                      alt={name}
                      style={{ objectFit: "contain", width: "80px" }}
                    />
                  </a>
                )
              })}
            </Grid>
          </Grid>
        ))}
      </div>
    </Layout>
  )
}

export default SponsorsPage
